<template>
  <div class="b-table__header">
    <div class="b-table__filter">
      <date-picker
        v-model="filter_date_range"
        :clearable="false"
        type="daterange"
        :open="datepicker_open"
        :editable="false"
        format="dd.MM.yyyy"
        class="elm-calendar hidden-picker"
        placeholder="Рабочий период"
        @on-change="setPeriod({ period: 'custom' })"
        @on-clickoutside="clickedOutside"
      />
      <b-dropdown
        no-caret
        variant="none"
        :disabled="datepicker_open"
      >
        <template #button-content>
          <div
            style="
              background: white;
              padding: 8px 10px;
              display: flex;
              flex-direction: row;
              height: 32px;
              border: 1px solid #bcbcbc;
            "
          >
            <span style="color: black">{{ getPeriod }}</span>
            <img
              src="/img/icons/calendar-dark.svg"
              alt="calendar"
              class="ml-2"
            />
          </div>
        </template>
        <b-dropdown-item
          class="sm-dropdown"
          @click.stop="datepicker_open = true"
        >
          Указать период
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'today' })"
        >
          Сегодня
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'yesterday' })"
        >
          Вчера
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'week' })"
        >
          Текущая неделя
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'lweek' })"
        >
          Прошлая неделя
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'month' })"
        >
          Текущий месяц
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'lmonth' })"
        >
          Прошлый месяц
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'lyear' })"
        >
          Последние 365 дней
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: null })"
        >
          Все время
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <b-button
      v-if="this.page_type === 'incoming' || 'cashless' || 'invoice'"
      v-b-toggle.incoming-sidebar
      class="filters-btn"
      variant="outline"
    >
      <img
        src="../../../../public/img/icons/filter.svg"
        alt=""
      />
      Фильтры
      <div
        v-if="countFilters"
        class="count-filters"
      >
        {{ countFilters }}
      </div>
    </b-button>
    <!-- <div v-if="filters" class="d-flex inc-filters ml-2">
      <i-select
        style="width: 230px"
        id="supplier"
        placeholder="Поставщики"
        class="inc-select-picker"
        :max-tag-count="1"
        :max-tag-placeholder="(val) => '+ Еще ' + val"
        v-model="suppliers"
        multiple
        filterable
        transfer
        clearable
        @on-change="updateFilters"
      >
        <i-option v-for="item in supplier_list" :value="item.id" :key="item.id">
          {{ item.name }}
        </i-option>
      </i-select>
      <i-select
        style="width: 230px"
        id="supplier"
        placeholder="Операции"
        class="inc-select-picker"
        :max-tag-count="1"
        :max-tag-placeholder="(val) => '+ Еще ' + val"
        v-model="operation"
        multiple
        filterable
        transfer
        @on-change="updateFilters"
      >
        <i-option
          v-for="item in incoming_operations"
          :value="item.key"
          :key="item.key"
        >
          {{ item.label }}
        </i-option>
      </i-select>
      <i-select
        style="width: 230px"
        id="supplier"
        placeholder="Место хранения"
        class="inc-select-picker"
        :max-tag-count="1"
        :max-tag-placeholder="(val) => '+ Еще ' + val"
        v-model="storages"
        multiple
        filterable
        transfer
        @on-change="updateFilters"
      >
        <i-option v-for="item in storage_list" :value="item.id" :key="item.id">
          {{ item.name }}
        </i-option>
      </i-select>
    </div> -->
    <div class="b-table__actions ml-auto">
      <b-button
        class="cursor"
        variant="primary"
        :disabled="creating"
        @click="createDocument"
      >
        <img
          src="/img/icons/btn-plus.svg"
          class="mr"
          alt=""
        />
        Добавить документ
      </b-button>
      <input
        id="fileUpload"
        type="file"
        hidden
        :v-model="file"
        @input="set_file"
      />
      <b-dropdown
        v-if="page_type === 'incoming' || 'contracts'"
        no-caret
        variant="none"
        class="ml-3"
      >
        <template #button-content>
          <div class="btn-more">
            <img
              src="/img/icons/more.svg"
              alt=""
            />
          </div>
        </template>
        <b-dropdown-item @click="choose_files"> Импорт из Excel </b-dropdown-item>
        <b-dropdown-item
          v-if="page_type === 'incoming'"
          @click="choose_files('upd_xml')"
        >
          Импорт УПД XML
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <filters-sidebar
      :pay_status_list="pay_status_list"
      :table_name="page_type"
      :status_list="status_list"
      :operations_list="getOperations"
      :supplier_list="supplier_list"
      :storage_list="storage_list"
      :types_list="types_list"
      :owners_list="owners_list"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import * as R from 'ramda'
  import FiltersSidebar from '@/components/sidebar/FiltersSidebar'

  export default {
    components: {
      FiltersSidebar
    },
    props: {
      page_type: {
        type: String,
        default: ''
      },
      file: {
        default: null
      },
      set_file: {
        type: Function,
        default: () => {}
      },
      choose_files: {
        type: Function,
        default: () => {}
      },
      filters: Boolean
    },
    apollo: {
      Entities: {
        query: require('../gql/getEntities.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.supplier_list = data.Entities || []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      Storages: {
        query: require('../gql/getStorages.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.storage_list = data.Storages || []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        },
        skip() {
          return this.page_type === 'invoice'
        }
      },
      UserFromCompany: {
        query: require('../gql/getOwners.graphql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.owners_list = data.UserFromCompany || []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data: () => ({
      filter_date_range: [],
      selected_type: null,
      datepicker_open: false,
      moddate: new Date(),
      creating: false,
      suppliers: [],
      storages: [],
      supplier_list: [],
      storage_list: [],
      operation: [],
      owners_list: [],
      pay_status_list: [
        { id: 'pay', name: 'Оплачен' },
        { id: 'no_pay', name: 'Не оплачен' }
      ],
      status_list: [
        { id: 'fixed', name: 'Проведен' },
        { id: 'no_fixed', name: 'Черновик' }
      ],
      types_list: [
        { id: 'entersight', name: 'Энтерсайт' },
        { id: 'crptech', name: 'Честный знак' },
        { id: 'egais', name: 'Егаис' },
        { id: 'evotor', name: 'Эвотор' },
        { id: 'sbis', name: 'СБИС' }
      ]
    }),

    computed: {
      ...mapGetters({
        dateRange: 'operprocess/getDateRange',
        currentBranch: 'settings/getCurrentBranch',
        getFilters: 'incoming/getFilters',
        getFiltersOutgoing: 'outgoing/getFilters',
        getFiltersCashless: 'cashless/getFilters',
        getFiltersInvoice: 'invoice/getFilters',
        getFiltersContracts: 'contracts/getFilters'
      }),
      countFilters() {
        if (this.page_type === 'incoming') {
          return (
            (this.getFilters.supplier?.length > 0 ? 1 : 0) +
            (this.getFilters.storages?.length > 0 ? 1 : 0) +
            (this.getFilters.operation?.length > 0 ? 1 : 0) +
            (this.getFilters.status?.length > 0 ? 1 : 0) +
            (this.getFilters.owners?.length > 0 ? 1 : 0) +
            (this.getFilters.types?.length > 0 ? 1 : 0)
          )
        }
        if (this.page_type === 'cashless') {
          return (
            (this.getFiltersCashless.supplier?.length > 0 ? 1 : 0) +
            (this.getFiltersCashless.operation?.length > 0 ? 1 : 0) +
            (this.getFiltersCashless.status?.length > 0 ? 1 : 0) +
            (this.getFiltersCashless.owners?.length > 0 ? 1 : 0)
          )
        }
        if (this.page_type === 'outgoing') {
          return (
            (this.getFiltersOutgoing.supplier?.length > 0 ? 1 : 0) +
            (this.getFiltersOutgoing.operation?.length > 0 ? 1 : 0) +
            (this.getFiltersOutgoing.status?.length > 0 ? 1 : 0) +
            (this.getFiltersOutgoing.owners?.length > 0 ? 1 : 0) +
            (this.getFiltersOutgoing.storages?.length > 0 ? 1 : 0) +
            (this.getFiltersOutgoing.types?.length > 0 ? 1 : 0)
          )
        }
        if (this.page_type === 'contracts') {
          return (
            (this.getFiltersContracts.supplier?.length > 0 ? 1 : 0) +
            (this.getFiltersContracts.storages?.length > 0 ? 1 : 0) +
            (this.getFiltersContracts.operation?.length > 0 ? 1 : 0) +
            (this.getFiltersContracts.status?.length > 0 ? 1 : 0) +
            (this.getFiltersContracts.owners?.length > 0 ? 1 : 0) +
            (this.getFiltersContracts.types?.length > 0 ? 1 : 0)
          )
        }
        return (
          (this.getFiltersInvoice?.status?.length > 0 ? 1 : 0) +
          (this.getFiltersInvoice?.owners?.length > 0 ? 1 : 0) +
          (this.getFiltersInvoice?.recipients?.length > 0 ? 1 : 0) +
          (this.getFiltersInvoice?.senders?.length > 0 ? 1 : 0) +
          (this.getFiltersInvoice?.pay_status?.length > 0 ? 1 : 0)
        )
      },

      getOperations() {
        if (this.page_type === 'incoming') {
          return [
            { key: 'income', label: 'Приемка' },
            { key: 'bonus', label: 'Бонус' },
            { key: 'commission', label: 'Комиссия' }
          ]
        } else if (this.page_type === 'outgoing') {
          return [
            { key: 'write_off', label: 'Списание' },
            { key: 'return', label: 'Возврат' }
          ]
        } else {
          return [
            { key: 'card', label: 'Безналичная продажа' },
            { key: 'cash', label: 'Наличная продажа' }
          ]
        }
      },

      getPeriod() {
        switch (this.selected_type) {
          case 'today': {
            return 'Сегодня'
          }
          case 'yesterday': {
            return 'Вчера'
          }
          case 'week': {
            return 'Текущая неделя'
          }
          case 'lweek': {
            return 'Прошлая неделя'
          }
          case 'month': {
            return 'Текущий месяц'
          }
          case 'lmonth': {
            return 'Прошлый месяц'
          }
          case 'lyear': {
            return 'Последние 365 дней'
          }
          case 'custom': {
            const start = new Intl.DateTimeFormat('ru-RU').format(
              this.filter_date_range[0] ? new Date(this.filter_date_range[0]) : new Date()
            )
            const end = new Intl.DateTimeFormat('ru-RU').format(
              this.filter_date_range[1] ? new Date(this.filter_date_range[1]) : new Date()
            )
            return start + ' - ' + end
          }
          default: {
            return 'За все время'
          }
        }
      }
    },

    mounted() {
      if (this.dateRange) {
        this.filter_date_range = this.dateRange.date
        this.setPeriod({ period: this.dateRange.type })
        this.updateDateRange(this.filter_date_range)
      }
    },

    methods: {
      ...mapActions({
        setDateRange: 'operprocess/setDateRange'
      }),

      clickedOutside() {
        this.datepicker_open = false
      },

      createDocument() {
        localStorage.setItem('newDocHeader', 'true')
        this.creating = true
        let queryType = ''
        let variables = {
          branch: this.currentBranch.id
        }

        switch (this.page_type) {
          case 'incoming':
            queryType = 'CreateIncomingDocument'
            break

          case 'contracts':
            queryType = 'CreateContractHead'
            variables = { input: {} }
            break

          case 'outgoing':
            queryType = 'CreateOutgoingDocument'
            break

          case 'cashless':
            queryType = 'CreateOutgoingDocument'
            break

          case 'movement':
            queryType = 'CreateMovementDocument'
            break
          case 'revaluation':
            queryType = 'CreateRevaluationHead'
            variables.operation = 'revaluation'
            break
          case 'invoice':
            queryType = 'createInvoice'
            break
          case 'order':
            queryType = 'CreateOrderHead'
            variables.operation = 'order'
            break
          case 'payment':
            queryType = 'CreateMoneyHead'
            variables.operation = 'payment'
            break
        }

        if (!['movement', 'invoice', 'order'].includes(this.page_type))
          variables.operation =
            this.page_type === 'incoming' ? `income` : this.page_type === 'cashless' ? 'cashless_sale' : `return`

        this.$apollo
          .mutate({
            mutation: require(`../gql/${queryType}.gql`),
            variables
          })
          .then(({ data }) => {
            const id = R.pathOr([], [queryType, 'id'], data)

            this.$router.push({
              name: `operation-process.overhead.${this.page_type}.document.edit`,
              params: { id: id, creation: true }
            })
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`При создании документа произошла ошибка. Попробуйте еще раз`)
          })
          .finally(() => {
            this.creating = false
          })
      },

      setPeriod({ period }) {
        switch (period) {
          case 'today': {
            this.filter_date_range[0] = new Date(new Date().setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date().setHours(23, 59, 59))
            break
          }
          case 'yesterday': {
            const today = new Date()
            this.filter_date_range[0] = new Date(new Date(new Date().setDate(today.getDate() - 1)).setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date(new Date().setDate(today.getDate() - 1)).setHours(23, 59, 59))
            break
          }
          case 'week': {
            let curr = new Date()
            let first = curr.getDate() - curr.getDay() + 1
            let last = first + 6
            this.filter_date_range[0] = new Date(new Date(curr.setDate(first)).setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date(curr.setDate(last)).setHours(23, 59, 59))
            break
          }
          case 'lweek': {
            let curr = new Date()
            let first = curr.getDate() - curr.getDay() - 6
            let last = first + 6
            this.filter_date_range[0] = new Date(new Date(curr.setDate(first)).setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date(curr.setDate(last)).setHours(23, 59, 59))
            break
          }
          case 'month': {
            let curr = new Date()
            this.filter_date_range[0] = new Date(curr.getFullYear(), curr.getMonth(), 1, 0, 0, 0)
            this.filter_date_range[1] = new Date(curr.getFullYear(), curr.getMonth() + 1, 0, 23, 59, 59)
            break
          }
          case 'lmonth': {
            let curr = new Date()
            this.filter_date_range[0] = new Date(curr.getFullYear(), curr.getMonth() - 1, 1, 0, 0, 0)
            this.filter_date_range[1] = new Date(curr.getFullYear(), curr.getMonth(), 0, 23, 59, 59)
            break
          }
          case 'lyear': {
            let curr = new Date()
            this.filter_date_range[0] = new Date(curr.getFullYear() - 1, curr.getMonth(), curr.getDay(), 0, 0, 0)
            this.filter_date_range[1] = new Date()
            break
          }
          case 'custom': {
            let dt = new Date(this.filter_date_range[1])
            dt.setHours(23, 59, 59)
            this.filter_date_range[1] = dt
            this.datepicker_open = false
            this.moddate = new Date()
            // const today = new Date()
            // if (isMount && today.getDate() !== new Date(this.getPeriod.moddate).getDate()) {
            //   this.setPeriod({ period: null })
            // }
            break
          }
          default: {
            this.filter_date_range = []
            break
          }
        }
        this.selected_type = period
        this.updateDateRange()
      },
      updateDateRange() {
        this.setDateRange({
          type: this.selected_type,
          date: this.filter_date_range,
          moddate: this.moddate
        })
        this.$emit('date_range', this.filter_date_range)
      },
      updateFilters() {
        this.$emit('update_filters', {
          operation: this.operation,
          storage: this.storages,
          supplier: this.suppliers
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .inc-filters {
      flex-wrap: wrap;
      grid-gap: 0.5rem;
    }
    .inc-select-picker {
      width: 230px;
      .ivu-tag {
        max-width: 110px;
      }
    }
    .datepicker-range {
      width: 210px;
    }

    .sm-dropdown > a {
      padding: 4px 12px !important;
    }

    .dropdown-menu {
      z-index: 2000 !important;
    }

    .hidden-picker > .ivu-date-picker-rel {
      max-width: 0px !important;
    }

    .hidden-picker > div > div > span {
      display: none;
    }
  }

  .b-table__header {
    display: flex;
  }
  .not-collapsed img {
    transform: rotate(0deg) !important;
  }
  .collapsed img {
    transform: rotate(0deg) !important;
  }
  .filters-btn {
    background: #fff;
    margin-left: 12px;
    border: 1px solid #bcbcbc;
    border-radius: 4px;
    align-items: center;
    font-family: 'Gilroy-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #313131;
    .count-filters {
      font-family: 'Gilroy-Medium', sans-serif;
      height: 18px;
      width: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 12px;
      color: #fff;
      border-radius: 4px;
      background-color: #00a3ff;
    }
    img {
      margin-right: 12px;
    }
  }

  .hidden-picker {
    max-width: 0px !important;
  }

  .hidden-picker > div > div > input {
    display: none;
  }
</style>
